<template>
  <v-theme-provider :dark="dark">
    <v-container class="pa-0">
      <v-row
          :justify="justify"
          no-gutters
      >
        <v-col
            v-if="image"
            :class="`text-${align}`"
            cols="12"
            class="mb-4"
        >
          <base-image :src="require(`@/assets/${image}`)">
          </base-image>
        </v-col>

        <v-col
            v-if="name || title"
            :cols="12"
        >

          <base-title
              :title="name"
              class="text-uppercase"
              space="0"
          />

          <base-subtitle
              v-if="title"
              :title="title"
              space="1"
          />

          <div class="d-flex justify-center">
            <v-btn :href="`mailto:${email}`" icon small>
              <base-icon small>
                mdi-email
              </base-icon>
            </v-btn>
            <v-btn :href="linkedin" target="_blank" icon small>
              <base-icon small>
                mdi-linkedin
              </base-icon>
            </v-btn>
          </div>

          <base-body
              v-if="text || $slots.default"
              :text="text"
              space="6"
          >
            <slot/>
          </base-body>
        </v-col>

      </v-row>
    </v-container>
  </v-theme-provider>
</template>

<script>
// Mixins
import Heading from '@/mixins/heading'
import BaseImage from "@/components/base/Img";

export default {
  name: 'BasePersonCard',
  components: {BaseImage},
  mixins: [Heading],

  props: {
    dark: Boolean,
    color: {
      type: String,
      default: 'primary',
    },
    image: String,
    text: String,
    name: String,
    title: String,
    email: String,
    linkedin: String,
  },
}
</script>

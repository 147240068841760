import WebFontLoader from 'webfontloader'

// async load fonts
WebFontLoader.load({
  google: {
    families: [
      'Open+Sans:300,400,600,700,900&display=swap',
    ],
  },
})

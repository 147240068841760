<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        :title="title"
        color="primary"
      >
        <slot />
      </base-info-card>

      <template v-for="({ icon, text, title: t }, i) in business">
        <base-avatar-card
          :key="i"
          :icon="icon"
          :outlined="false"
          :title="!dense ? t : undefined"
          color="transparent"
          horizontal
          space="0"
        >
          <div v-html="text" :class="{'white--anchors': dark, 'unset--anchors': !dark}" />
        </base-avatar-card>

        <v-divider
          v-if="i + 1 !== business.length"
          :key="`divider-${i}`"
          class="my-2"
        />
      </template>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessContact',

    props: {
      dark: Boolean,
      dense: Boolean,
      title: String,
    },

    data: () => ({
    }),
    computed: {
      business() {
        return [
          {
            icon: 'mdi-map-marker-outline',
            title: this.$t('address'),
            text: 'Ethos Evaluate AB<br>Östermalmsgatan 26 A<br>Stockholm<br>SWEDEN',
          },
          {
            icon: 'mdi-cellphone',
            title: this.$t('phone'),
            text: ' +46 (0)8 720 54 00',
          },
          {
            icon: 'mdi-email',
            title: this.$t('email'),
            text: '<a href="mailto:info@evaluate.se">info@evaluate.se</a>',
          },
        ];
      },
    }
  }
</script>

<style>
  .white--anchors a {
    color: #fff !important;
    text-decoration: none;
  }
  .unset--anchors a {
    color: unset !important;
    text-decoration: none;
  }
</style>

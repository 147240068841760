<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-img
        :src="require('@/assets/evaluate-white.svg')"
        contain
        max-width="120"
        width="100%"
        class="mb-4"
      />

      <base-body>
        {{ $t('brand.about') }}
      </base-body>

    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
    },

    data: () => ({
    }),
  }
</script>

import Vue from 'vue'
import Router from 'vue-router'
import i18n from '../i18n'

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/:locale?',
      component: () => import('@/layouts/home/Index.vue'),
      beforeEnter: (to, from, next) => {
        //const hostLocale = window.location?.hostname && window.location.hostname.toLowerCase().endsWith('.se') ? 'sv' : 'en';
        const hostLocale = 'en';
        const locale = to.params.locale;
        const supported_locales = ['en', 'sv'];
        if (!supported_locales.includes(locale)) return next(hostLocale);
        if (i18n.locale !== locale) {
          i18n.locale = locale;
        }
        return next();
      },
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: 'article/:slug',
          name: 'Article',
          component: () => import('@/views/article/Index.vue'),
          props: true,
        },
        {
          path: 'about',
          name: 'About',
          component: () => import('@/views/about/Index.vue'),
          meta: { src: require('@/assets/about.jpg') },
        },
        {
          path: 'business-partner',
          name: 'BusinessPartnerInfo',
          component: () => import('@/views/business-partner-info/Index.vue'),
          meta: { src: require('@/assets/about.jpg') },
        },
        {
          path: 'due-diligence',
          name: 'DueDiligence',
          component: () => import('@/views/due-diligence/Index.vue'),
          meta: { src: require('@/assets/about.jpg') },
        },
        {
          path: 'reporting',
          name: 'Reporting',
          component: () => import('@/views/reporting/Index.vue'),
          meta: { src: require('@/assets/about.jpg') },
        },
        {
          path: 'contact-us',
          name: 'ContactUs',
          component: () => import('@/views/contact-us/Index.vue'),
          meta: { src: require('@/assets/contact.jpg') },
        },
        {
          path: 'customer-stories',
          name: 'CustomerStories',
          component: () => import('@/views/customer-stories/Index.vue'),
          meta: { src: require('@/assets/hero3.jpg') },
        },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },
  ],
})

export default router

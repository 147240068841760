<template>
  <div>
    <v-snackbar
        v-model="snack"
        :timeout="snackTimeout"
    >
      {{ snackMessage }}
    </v-snackbar>
    <base-info-card
        :title="title"
        :subtitle="subtitle"
        space="4"
        color="primary"
    />

    <v-form ref="contact">
      <base-text-field :label="$t('name')" v-model="contact.name" :rules="requiredRule" id="name"/>
      <base-text-field :label="$t('company')" v-model="contact.company" id="company"/>
      <base-text-field :label="$t('email')" v-model="contact.email" :rules="requiredRule" id="email" type="email"/>
      <base-text-field :label="$t('phone')" v-model="contact.phone" id="phone"/>
      <base-textarea
          class="mb-6"
          id="message"
          v-model="contact.message"
          :label="$t('need_and_message')"
      />
      <base-btn
          :color="!theme.isDark ? 'accent' : 'white'"
          @click="recaptcha"
          outlined
          target="_blank"
          :disabled="isSending"
      >
        {{ $t('send_message') }}
      </base-btn>
    </v-form>
  </div>
</template>

<script>

// import {VueReCaptcha} from 'vue-recaptcha-v3'
// import Vue from 'vue'
import axios from 'axios'

export default {
  name: 'BaseContactForm',

  // Injected from the Vuetify Themeable mixin
  inject: ['theme'],
  data: () => ({
    isSending: false,
    snack: false,
    snackMessage: '',
    snackTimeout: 5000,
    contact: {
      name: '',
      company: '',
      email: '',
      phone: '',
      message: '',
      recaptcha: '',
    }
  }),
  computed: {
    requiredRule() {
      let req = this.$t('required');
      return [
        v => !!v || req
      ];
    },
  },
  props: {
    subtitle: String,
    title: {
      type: String,
      default: 'SEND US A MESSAGE',
    },
  },
  mounted() {
    // Vue.use(VueReCaptcha, {siteKey: '6LcVO-cZAAAAAB6noB7KdiqOHjbzDdsJQuUD51fh'});
  },
  methods: {
    async recaptcha() {
      // await this.$recaptchaLoaded();
      // this.contact.recaptcha = await this.$recaptcha('login');
      // if (this.contact.recaptcha) {
      //   this.isSending = true;
      //   axios.post('/actions/contact', this.contact).then(res => {
      //     if (res.data.success) {
      //       this.snackMessage = this.$t('contact_success');
      //       this.snack = true;
      //       this.$refs.contact.reset();
      //     }
      //   }).catch(err => {
      //     this.snackMessage = this.$t('contact_failure');
      //     this.snack = true;
      //     console.error(err);
      //   }).finally(() => {
      //     this.isSending = false;
      //   });
      // }
      this.isSending = true;
      axios.post('/actions/contact', this.contact).then(res => {
        if (res.data.success) {
          this.snackMessage = this.$t('contact_success');
          this.snack = true;
          this.$refs.contact.reset();
          this.$gtag.query('event', 'conversion', {
            'send_to': 'AW-692862322/4e26CLnq4qwDEPL6sMoC',
          });
        }
      }).catch(err => {
        this.snackMessage = this.$t('contact_failure');
        this.snack = true;
        console.error(err);
      }).finally(() => {
        this.isSending = false;
      });
    },
  }
}
</script>

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import {
  VCol,
  VRow,
} from 'vuetify/lib'

Vue.use(Vuetify, {
  components: {
    VCol,
    VRow,
  },
})

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#183d72',
        secondary: '#536878',
        tertiery: '#8898aa',
        accent: '#2867bf',
        error: '#aa0000',
        danger: '#aa0000',
        info: '#2867bf',
        success: '#228b22',
        warning: '#fab000',
      },
      dark: {},
    },
  },
})

<template>
  <v-theme-provider :dark="dark">
    <v-container class="pa-0">
      <v-row
          :justify="justify"
          no-gutters
      >
        <v-col
            v-if="image"
            :class="`text-${align}`"
            cols="12"
            class="mb-4"
        >
          <router-link :to="`/${lang}/article/${id}-${slug}`">
            <base-image :src="`https://strapi.ethosevaluate.net${image}`" max-height="150">
            </base-image>
          </router-link>
        </v-col>

        <v-col
            v-if="title"
            :cols="12"
            class="anchors-decoration-none"
        >

          <base-subtitle
              v-if="subtitle"
              :title="subtitle"
              space="1"
          />

          <router-link :to="`/${lang}/article/${id}-${slug}`">
            <base-title
                :title="title"
                space="2"
            />
          </router-link>

          <base-body
              v-if="lead"
              :text="lead"
              space="6"
          >
            <slot/>
          </base-body>
        </v-col>

      </v-row>
    </v-container>
  </v-theme-provider>
</template>

<script>
// Mixins
import Heading from '@/mixins/heading'
import BaseImage from "@/components/base/Img";

export default {
  name: 'BaseCaseCard',
  components: {BaseImage},
  mixins: [Heading],

  props: {
    dark: Boolean,
    color: {
      type: String,
      default: 'primary',
    },
    image: String,
    id: Number,
    lead: String,
    title: String,
    subtitle: String,
    slug: String,
    lang: String,
  },
}
</script>

<style>
.anchors-decoration-none a {
  text-decoration: none !important;
}
</style>
